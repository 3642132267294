import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import educ from "../assets/img/school.jpg";
import CompactCardFixedLeft from './compactCard/compactCardFixedLeft';

const Education = () => {
  const { t } = useTranslation();

  const items = [
    {
      major: true,
      title: t("Ecole d'ingénieur"),
      period: "2011 - 2014",
      compagny: "ISIMA",
      city: "Clermont-Ferrand",
      country: "France",
      shortDescription: t("isima short description"),
    },
    {
      major: true,
      title: t("CPGE ATS"),
      period: "2010 - 2011",
      compagny: "LYCEE LAFAYETTE",
      city: "Clermont-Ferrand",
      country: "France",
      shortDescription: t("cpge short description"),
    },
    {
      major: true,
      title: t("DUT informatique"),
      period: "2008 - 2011",
      compagny: "UNIVERSITÉ D'AUVERGNE",
      city: "Clermont-Ferrand",
      country: "France",
      shortDescription: t("DUT short description"),
    },
  ];

  return (
    <Fragment>
      <CompactCardFixedLeft
        pic={educ}
        center={true}
        category={t("eduction section title")}
        title="certif."
        list={items}
        labelList="Étapes"
      />
    </Fragment>
  );
};

export default Education;
